<template>
  <div>
    <b-card no-body class="mb-0">
      <b-overlay :show="loading" rounded="sm" no-fade>
        <div class="m-2">
          <b-row>
            <!-- Per Page -->
            <b-col
              cols="12"
              md="7"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
            </b-col>

            <!-- Search -->
            <b-col cols="12" md="5">
              <div class="d-flex align-items-center justify-content-end">
                <b-button variant="primary" @click="setRequired()">
                  <span class="text-nowrap">Set Required Inputs</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-container>
          <b-form>
            <b-row class="mt-2">
              <b-col cols="12" xl="12" md="12" style="margin-bottom: 6rem;">
                <b-tabs
                  vertical
                  nav-wrapper-class="nav-vertical"
                  class="tab-class"
                >
                  <b-tab active title="Inventory">
                    <b-col
                      cols="12"
                      xl="12"
                      md="12"
                      sm="12"
                      class="bodyRow"
                      style="padding: 0.5rem 0;"
                    >
                      <b-row>
                        <b-col cols="4" xl="4" md="4">
                          <b-table
                            hover
                            responsive
                            small
                            fixed
                            :items="inputs1"
                            class="position-relative"
                            :fields="fields"
                            style="padding-left: 1rem;"
                          >
                            <template #cell(inputs)="data">
                              <strong v-if="data.item.name != 'id'">{{
                                data.item.name
                                  .replace(/([A-Z])/g, " $1")
                                  .replace(/^./, function(str) {
                                    return str.toUpperCase();
                                  })
                              }}</strong>
                            </template>

                            <template #cell()="data">
                              <b-form-checkbox
                                v-if="data.item.name != 'id'"
                                name="check-button"
                                switch
                                size="sm"
                                v-model="data.item.value"
                              >
                              </b-form-checkbox>
                            </template>
                          </b-table>
                        </b-col>
                        <b-col cols="4" xl="4" md="4">
                          <b-table
                            hover
                            responsive
                            small
                            fixed
                            :items="inputs2"
                            class="position-relative"
                            :fields="fields"
                            style="padding-left: 1rem;"
                          >
                            <template #cell(inputs)="data">
                              <strong v-if="data.item.name != 'id'">{{
                                data.item.name
                                  .replace(/([A-Z])/g, " $1")
                                  .replace(/^./, function(str) {
                                    return str.toUpperCase();
                                  })
                              }}</strong>
                            </template>

                            <template #cell(req)="data">
                              <b-form-checkbox
                                v-if="data.item.name != 'id'"
                                name="check-button"
                                switch
                                size="sm"
                                v-model="data.item.value"
                              >
                              </b-form-checkbox>
                            </template>
                          </b-table>
                        </b-col>
                        <b-col cols="4" xl="4" md="4">
                          <b-table
                            hover
                            responsive
                            small
                            fixed
                            :items="inputs3"
                            class="position-relative"
                            :fields="fields"
                            style="padding-left: 1rem;"
                          >
                            <template #cell(inputs)="data">
                              <strong v-if="data.item.name != 'id'">{{
                                data.item.name
                                  .replace(/([A-Z])/g, " $1")
                                  .replace(/^./, function(str) {
                                    return str.toUpperCase();
                                  })
                              }}</strong>
                            </template>

                            <template #cell(req)="data">
                              <b-form-checkbox
                                v-if="data.item.name != 'id'"
                                name="check-button"
                                switch
                                size="sm"
                                v-model="data.item.value"
                              >
                              </b-form-checkbox>
                            </template>
                          </b-table>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-form>
        </b-container>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import axiosIns from "@/libs/axios";
import {
  BFormGroup,
  BTable,
  BRow,
  BCol,
  BFormSelect,
  BPagination,
} from "bootstrap-vue";
import store from "@/store";
import settingsStoreModule from "../settingsStoreModule";
import { onUnmounted } from "@vue/composition-api";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { BTabs, BTab, BCardText } from "bootstrap-vue";
import _ from "lodash";

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    ToastificationContent,
    BCardText,
    BTabs,
    BTab,
  },

  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = "settings";

    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
      store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME))
        store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });

    const toast = useToast();
  },

  mounted() {
    this.getRequiredInputs();
  },

  data() {
    return {
      requiredInputs: [],
      newInput: "",
      inputs: [],
      inputs1: [],
      inputs2: [],
      inputs3: [],
      loading: false,
      fields: [
        {
          key: "inputs",
          label: "Inputs",
          thClass: "text-left",
          tdClass: "text-left",
          thStyle: "width:66%",
        },
        {
          key: "req",
          label: "-",
          thStyle: "width:33%",
          thClass: "text-left",
          tdClass: "text-left",
        },
      ],
      id: 1,
    };
  },
  computed: {},
  methods: {
    getRequiredInputs() {
      this.loading = true;
      store
        .dispatch("settings/fetchInputs")
        .then((res) => {
          this.id = res.data[0] ? res.data[0].id : 1;
          res.data = _.map(res.data, (o) => _.omit(o, ["id"]));

          // one object items converted to array each items
          for (const [key, value] of Object.entries(res.data[0])) {
            this.inputs.push({
              name: key,
              value: value,
            });
          }

          const chunks = _.chunk(this.inputs, 16);
          this.inputs1 = chunks[0];
          this.inputs2 = chunks[1];
          this.inputs3 = chunks[2];

          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    removeItem(event, id) {
      this.$swal({
        title: "Are you sure?",
        text: "",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          event.preventDefault();
          axiosIns
            .delete(`body/${id}`)
            .then((res) => {
              if (res.status == 200) {
                this.getRequiredInputs();
              }
            })
            .catch((error) => {
              console.log("error delete ", error);
            });
        }
      });
    },
    setRequired() {
      this.loading = true;

      // two table arrays to compare one array
      let compareArrays = _.concat(this.inputs1, this.inputs2, this.inputs3);

      //all arrays data converted to one object
      const arraysToObject = {};
      compareArrays.forEach(({ name, value }) =>
        Object.assign(arraysToObject, { [name]: value })
      );

      axiosIns
        .put(`setRequiredInputs/${this.id}`, arraysToObject)
        .then((response) => {
          if (response.status == 200) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Successful",
                text: "✔️ Inputs Updated Successfully",
                icon: "ThumbsUpIcon",
                variant: "success",
              },
            });
            this.loading = false;
            this.getRequiredInputs();
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                text: "Inputs Updated Failed",
                icon: "ThumbsDownIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((error) => {
          console.log("error inputs => ", error);
        });
    },
  },
  created() {
    //   this.getRequiredInputs();
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
.modal-body {
  overflow-x: hidden;
  padding: 0;
}
@import "@core/scss/vue/libs/vue-select.scss";
</style>
